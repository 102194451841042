import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { ReligioUrl } from '../Api/ApiUrl';
import { Link } from 'react-router-dom';
import Marquee from 'react-fast-marquee';


const FlashNews = () => {
    const [latestNews, setLatestNews] = useState([]);
    const marqueeRef = useRef(null);

    useEffect(() => {
        const fetchLatestNews = async () => {
            try {
                const response = await axios.get(`${ReligioUrl}/news/province/1`);
                const newsData = response?.data?.data;
                
                if (Array.isArray(newsData) && newsData.length > 0) {
                    const sortedNews = newsData.sort((a, b) => b.id - a.id);
                    setLatestNews(sortedNews.slice(0, 5)); // Fetch the latest 5 news items
                }
            } catch (error) {
                console.log('Error fetching data:', error);
                setLatestNews([{ name: 'Error fetching news' }]);
            }
        };

        fetchLatestNews();
    }, []);

    const handleMouseOver = () => {
        if (marqueeRef.current) {
            marqueeRef.current.stop();
        }
    };

    const handleMouseOut = () => {
        if (marqueeRef.current) {
            marqueeRef.current.start();
        }
    };

    return (
        <div className="hdTopBar py-2 py-xl-3 bg-dark d-none d-md-block">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="topNews d-flex align-items-center overflow-hidden">
                            <span>Flash News</span>
                            <div className="cityNewsSlider w-100">
                                <div className='text-white'>
                                    {latestNews.length > 0 && (
                                        <Marquee
                                            ref={marqueeRef}
                                            style={{ marginTop: "10px" }}
                                            onMouseOver={handleMouseOver}
                                            onMouseOut={handleMouseOut}
                                        >
                                            {latestNews.map((news, index) => (
                                                <span key={index}>
                                                    <Link to={`/all-upcome-news?newsid=${encodeURIComponent(btoa(news.id))}`} className='text-white'>
                                                        <i className="icn fa fa-star" aria-hidden="true" /> {news.name} <i className="icn fa fa-star" aria-hidden="true" />
                                                    </Link>
                                                    {index < latestNews.length - 1 && ' | '}
                                                </span>
                                            ))}
                                        </Marquee>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FlashNews;
